import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {environment} from '../../environments/environment';
import {DisbursementTokenRes, InstitutionConfiguration, TempTokenRes} from './app.types';

@Injectable({providedIn: 'root'})
export class AppApiService {

  constructor(private httpClient: HttpClient) {
  }

  sendPaymentToken(disbursementToken: string, paymentCardToken: string): Observable<any> {
    const body = {
      disbursementToken,
      paymentCardToken
    }

    const headers = {};
    return this.httpClient.post<any>(
      `${environment.baseUrl}/disbursement/api/disbursements/payment-token`, body,
      {
        headers
      }
    );
  }

  validateToken(token: String): Observable<DisbursementTokenRes> {
    const headers = {};
    return this.httpClient.get<DisbursementTokenRes>(
      `${environment.baseUrl}/disbursement/api/disbursements/disbursement-token/status?disbursementToken=${token}`,
      {
        headers
      }
    );
  }

  getTempToken(): Observable<TempTokenRes> {
    const headers = {};

    return this.httpClient.get<TempTokenRes>(
      `${environment.baseUrl}/disbursement/api/disbursements/berkeley/temp-token`,
      {
        headers
      }
    );
  }

  getInstitutionConfig(): Observable<InstitutionConfiguration> {
    const headers = {};
    return this.httpClient.get<InstitutionConfiguration>(
      `${environment.baseUrl}/institution/api/institution/configuration`,
      {
        headers
      }
    );
  }
}
