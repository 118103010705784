import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, catchError, finalize, map, Observable, of, switchMap, tap} from 'rxjs';

import {environment} from '../../environments/environment';
import {InstitutionConfiguration} from './app.types';
import {AppApiService} from './app-api.service';

@Injectable({providedIn: 'root'})
export class AppService {
  pending$: Observable<boolean>;
  error$: Observable<string>;
  tokenError$: Observable<string>;
  institutionConfig$: Observable<InstitutionConfiguration>;
  configPending$: Observable<boolean>;
  configError$: Observable<HttpErrorResponse>;

  private _pending$ = new BehaviorSubject<boolean>(false);
  private _error$ = new BehaviorSubject<string>(null);
  private _tokenError$ = new BehaviorSubject<string>(null);
  private _institutionConfig$ = new BehaviorSubject<InstitutionConfiguration>(null);
  private _configPending$ = new BehaviorSubject<boolean>(false);
  private _configError$ = new BehaviorSubject<HttpErrorResponse>(null);

  constructor(private appApiService: AppApiService) {
    this.pending$ = this._pending$.asObservable();
    this.error$ = this._error$.asObservable();
    this.tokenError$ = this._tokenError$.asObservable();
    this.institutionConfig$ = this._institutionConfig$.asObservable();
    this.configPending$ = this._configPending$.asObservable();
    this.configError$ = this._configError$.asObservable();
  }

  getIframeUrl(token: string): Observable<string> {
    this._pending$.next(true);
    this._error$.next(null);
    return this.appApiService.validateToken(token)
      .pipe(
        map((res) => {
          if (!res.valid) {
            throw (this.getTokenError(res?.reason));
          } else {
            return res;
          }
        }),
        switchMap(
          () => this.appApiService.getTempToken()
            .pipe(map((res) => {
              if (res?.data?.temp_token) {
                return `${environment.iframeBaseUrl}/iframe/v1/forms?t=${res?.data?.temp_token}&form_type=card&region=us`;
              } else {
                throw ('Failed to load form, please try again later!');
              }
            }))
        ),
        catchError((err) => {
          const message = typeof err === 'string' ? err : 'Failed to load form, please try again later!';
          this._error$.next(message);
          return of(null);
        }),
        finalize(() => {
          this._pending$.next(false);
        })
      );
  }

  sendPaymentToken(disbursementToken: string, paymentCardToken: string): Observable<any> {
    this._pending$.next(true);
    this._tokenError$.next(null);
    return this.appApiService.sendPaymentToken(disbursementToken, paymentCardToken)
      .pipe(
        catchError((err) => {
          const message = typeof err === 'string' ? err : `This card doesn't support fast fund or was entered incorrectly. Please refresh the page and enter your <span class="highlight">DEBIT CARD</span> information.`;
          this._tokenError$.next(message);
          return of(null);
        }),
        finalize(() => {
          this._pending$.next(false);
        })
      );
  }

  getInstitutionConfig(): Observable<InstitutionConfiguration> {
    this._configPending$.next(true);
    this._configError$.next(null);
    return this.appApiService.getInstitutionConfig()
      .pipe(
        tap((res) => {
          this._institutionConfig$.next(res)
        }),
        finalize(() => {
          this._configPending$.next(false);
        }),
        catchError((err) => {
          this._configError$.next(err);
          return of(null);
        })
      )
  }

  private getTokenError(reason): string {
    let message = 'Something Wrong';
    if (reason) {
      if (reason === 'error.disbursement.tokenExpired') {
        message = 'This transaction has expired. The transaction was not completed within the allotted 5 days and was canceled.';
      } else if (reason === 'error.disbursement.cancelled') {
        message = 'Disbursement Canceled';
      } else if (reason === 'error.disbursement.cardDisbursementForbidden') {
        message = 'Card Disbursement Forbidden';
      } else if (reason === 'error.disbursement.paymentCardDataHasBeenProvided') {
        message = 'Payment Card Data Has Been Provided';
      } else if (reason === 'error.disbursement.tokenInvalid') {
        message = 'Token Invalid';
      } else if (reason === 'error.disbursement.redeemed') {
        message = 'This transaction was redeemed successfully and is no longer active.';
      } else if (reason === 'error.disbursement.convertedToCheckByAdmin') {
        message = 'This transaction is no longer valid as the funds were converted to a physical check by your school’s administrator.';
      }
    }
    return message;
  }
}
