import {ChangeDetectionStrategy, Component, Renderer2} from '@angular/core';
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {filter, fromEvent} from "rxjs";

@UntilDestroy()
@Component({
  selector: 'hea-contact-popup',
  templateUrl: './contact-popup.component.html',
  styleUrls: ['./contact-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class ContactPopupComponent {

  public isOpen = false;

  constructor(private renderer: Renderer2) {
    fromEvent(document, 'keyup')
      .pipe(
        filter(() => this.isOpen),
        untilDestroyed(this)
      )
      .subscribe((e: any) => {
        if (e.keyCode !== 27) {
          return;
        }
        this.close();
      });
  }

  open() {
    this.renderer.addClass(document.body, 'noscroll');

    this.isOpen = true;

  }

  close() {
    this.isOpen = false;
    this.renderer.removeClass(document.body, 'noscroll');
  }
}
