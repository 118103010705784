import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {CardErrorComponent} from "./card-error";
import {ContactPopupComponent} from "./contact-popup";
import {ErrorComponent} from './error';
import {LetDirective} from './let-context.directive';
import {LoaderComponent} from './loader';
import {SafePipe} from './safe.pipe';
import {SuccessComponent} from './success';
import {WarningComponent} from './warning';


@NgModule({
  declarations: [
    AppComponent,
    CardErrorComponent,
    ContactPopupComponent,
    ErrorComponent,
    WarningComponent,
    SuccessComponent,
    LoaderComponent,
    SafePipe,
    LetDirective
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
