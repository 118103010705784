<ng-container *heaNgLet="pending$ | async as pending">
  <ng-container *heaNgLet="error$ | async as error">
    <ng-container *heaNgLet="tokenError$ | async as tokenError">
      <header>
        <img [src]="logoUrl"
             class="logo" alt="School Logo"/>
        <img src="assets/powered_by_hank.png" class="powered_by" alt="powered by Hank"/>
      </header>
      <div class="card">
        <hea-loader *ngIf="pending"></hea-loader>

        <div *ngIf="!successVisible">

          <hea-error [message]="error"></hea-error>

          <ng-container *ngIf="(iframeUrl$ | async) as iframeUrl">
            <h2>Add your payment card</h2>
            <h5>You can use your debit card to get your credit disbursement. Please enter required information
              below.</h5>
            <hea-warning *ngIf="iframeUrl && !error"></hea-warning>

            <div class="h_iframe">

              <iframe id="Iframe"
                      allowfullscreen
                      [src]="iframeUrl | safe:'resourceUrl'"
              ></iframe>

            </div>
          </ng-container>
        </div>

        <hea-success *ngIf="successVisible && !tokenError"></hea-success>

        <hea-card-error *ngIf="tokenError" [message]="tokenError"></hea-card-error>
      </div>

      <footer>
        <ul class="help-list">
          <li>Need help?&nbsp;</li>
          <li><a href="#" (click)="contactUs($event)">Contact Us</a></li>
        </ul>
        <div class="footer_bottom">
        <span class="copy">
          Copyright © 2023 Hank Payments Corp. All Rights Reserved
        </span>
        </div>
      </footer>
      <hea-contact-popup></hea-contact-popup>
    </ng-container>
  </ng-container>
</ng-container>
