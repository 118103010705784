<div *ngIf="isOpen" class="contact-popup">
  <button (click)="close()" class="close-popup">
    🗙
  </button>
  <div class="contact-popup-content">
    <h2 class="contact-popup-title">Contact Us</h2>
    <img class="contact-popup-image" src="assets/call-center.png"/>
    <h5>Need Help?</h5>
    <p>Hank specialists are standing by</p>
    <p>Call now: <a href="tel:866-311-9080">866-311-9080</a></p>
    <p> Our hours of operation:<br> Monday-Friday 9am-5pm ET </p>
    <p> Email us: <a href="mailto:studentsupport@hankpayments.com">studentsupport@hankpayments.com</a></p>
  </div>
</div>


