import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {Observable} from 'rxjs';

import {environment} from '../environments/environment';
import {ContactPopupComponent} from "./contact-popup";
import {AppService} from './entity';

@Component({
  selector: 'hea-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  iframeUrl$: Observable<string>;
  pending$ = this.appService.pending$;
  error$ = this.appService.error$;
  tokenError$  = this.appService.tokenError$;
  successVisible = false;
  @ViewChild(ContactPopupComponent) popup!: ContactPopupComponent;
  logoUrl = environment.whiteLabeling.logoUrl;

  private readonly urlParams = new URLSearchParams(window.location.search);
  private readonly disbursementToken = this.urlParams.get('disbursementToken');

  constructor(
    private appService: AppService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
  }

  ngOnInit() {
    this.iframeUrl$ = this.appService.getIframeUrl(this.disbursementToken);
    this.listenIframeMessages();
  }

  private listenIframeMessages() {
    window.addEventListener("message", async (e) => {
      const {data} = e;
      if (data.height) {
        const iframeEl: any = document.getElementById("Iframe");
        if (iframeEl) {
          iframeEl.height = data.height;
          this.changeDetectorRef.detectChanges();
        }
      }

      const paymentCardToken = data?.data?.token;
      if (!paymentCardToken) {
        return;
      }

      this.appService.sendPaymentToken(this.disbursementToken, paymentCardToken)
        .subscribe({
          next: () => {
            this.showSuccess();
          }
        });
    })
  }

  showSuccess(): void {
    this.successVisible = true;
  }

  contactUs($event){
    $event.preventDefault();
    this.popup.open();
  }
}
