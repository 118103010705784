import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'hea-warning',
  templateUrl: './warning.component.html',
  styleUrls: ['./warning.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WarningComponent {
  @Input() message = `<ul>
        <li>You have 3 minutes to complete and submit your card information. If expired, please refresh and enter it again</li>
         <li>Please enter your DEBIT CARD information to get your disbursement. Note that credit cards are not accepted</li>
        </ul>`
}
