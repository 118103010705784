import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'hea-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErrorComponent {
  @Input() message: string;
}
